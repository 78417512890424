var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"位置"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'location',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'location',\n                {\n                  rules: [\n                    { required: true, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"选择"}},_vm._l((this.$Dictionaries.CorporationLocation),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1),_c('a-form-item',{attrs:{"label":"面积(平方米)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['area', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['area', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }