<template>
  <a-card :bordered="false">
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row>
        <a-col :span="10" :offset="5">
          <a-form-item label="名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="编号" >
            <a-input v-decorator="['code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="面积(平方米)" >
            <a-input v-decorator="['area', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="位置" >
            <a-select
              v-decorator="[
                'location',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ]
                },
              ]"
              placeholder="选择"
            >
              <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
        style="width: 60%; margin: 0 auto"
      >
        <a-row>
          <a-col span="8">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="6">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="10"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { bonded_warehouse_list, bonded_warehouse_update } from '@/api/bonded_warehouse'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      bonded_warehouse_list({ id: this.$route.params.id })
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          const list = data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        bonded_warehouse_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(form, data)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'code', 'location', 'area'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
